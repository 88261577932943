body {
    font-family: Arial, Helvetica, sans-serif;
    background-color: #c8e6c9;
    color: #333;
}

.truncated {
    width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media screen and (max-width: 600px){
    .brand-logo.center {
        font-size: 22px;
    }

    .truncated {
        width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

